import { defineComponent } from 'vue';
import { formatPriceMixins } from './price/format';
import { getGlobalConfig } from '@monolith/legacy/services/global-config';

export const welcomeOfferMinimumBrands = 4;

export const seeYouSoonOfferMinimumBrands = 4;
//TODO: migrate into utility file
export const promocodeMixin = defineComponent({
  name: 'PromocodeMixin',
  mixins: [formatPriceMixins],
  computed: {
    welcomeOffer(): {
      amount: string;
      minimumCheckoutAmount: string;
      minimumBrands: number;
    } {
      return {
        amount: this.formatPrice(getGlobalConfig().new_welcome_offer_amount, '$0'),
        minimumCheckoutAmount: this.formatPrice(getGlobalConfig().new_welcome_offer_minimum_checkout_amount, '$0'),
        minimumBrands: welcomeOfferMinimumBrands,
      };
    },
    seeYouSoonOffer(): { amount: string; minimumBrands: number } {
      return {
        amount: this.formatPrice(getGlobalConfig().see_you_soon_offer_amount, '$0'),
        minimumBrands: seeYouSoonOfferMinimumBrands,
      };
    },
  },
});
