import { NavigationGuardNext, RouteLocationNormalized, Router } from 'vue-router';
import { FeatureFlag, isEnabled } from '@monolith/legacy/services/features';
import Store from '@monolith/legacy/store';
import { captureException } from '@core/plugins/sentry';
import { getEmailCampaign, getPaginatedEmailCampaigns, getPaginatedEmailRecipients } from '@monolith/legacy/services/api/email-campaigns';
import http from '@monolith/legacy/services/api/http';
import { getIdFromSlug } from '@core/utilities/slug';
import BrandTracking from '@monolith/legacy/services/brand-tracking';
import loadCarts from '@core/routes/middleware/load-carts';
import i18n, { loadLanguageAsync } from '@monolith/legacy/services/i18n';
import { SaveBarMode } from '@monolith/legacy/store/account/types';
import { EditionMode } from '@monolith/legacy/store/account/products';
import Analytics from '@monolith/legacy/services/analytics';
import { syncLanguageSwitcherUrls } from '@core/utilities/sync-language-switcher';
import { FETCH_OFFERS_AND_EVENTS } from '@monolith/legacy/store/offers/action-types';

export async function orderListGuard(_to, _from, next: NavigationGuardNext) {
  if (Store.getters.isUserAuthenticated && !Store.getters.userIsRetailer) {
    try {
      await Store.dispatch('account/publicOrdersIntegration/getActiveIntegration');
    } catch (e) {
      captureException(e);
    }
  }
  next();
}

export function preparedOrderGuard(_to, _from, next: NavigationGuardNext) {
  if (isEnabled('prepared_orders')) {
    next();
  } else {
    next({ path: '/account/orders' });
  }
}

export function editPreparedOrderGuard(_to, _from, next: NavigationGuardNext) {
  if (isEnabled('save_draft_new_order')) {
    next();
  } else {
    next({ name: 'account.prepared-orders.preview' });
  }
}

export async function accountIntegrationsGuard(_to, _from, next: NavigationGuardNext) {
  await Store.dispatch('account/publicOrdersIntegration/getEnabledIntegrations');
  await Store.dispatch('account/publicOrdersIntegration/getActiveIntegration');
  next();
}

export async function campaignGuard(to, _from, next: NavigationGuardNext) {
  const currentPage = parseInt(<string>to.query.page ?? '1');
  const data = await getPaginatedEmailCampaigns(currentPage);
  // vue-router Route type expects all params to be strings but when passing more
  // complex params as props this is no longer the case.
  to.params.campaigns = <string>(<unknown>data);
  next();
}

export async function campaignDetailGuard(to, _from, next: NavigationGuardNext) {
  const campaign = await getEmailCampaign(parseInt(to.params.id));
  to.params.campaign = <string>(<unknown>campaign);
  next();
}

export async function campaignRecipientsGuard(to, _from, next: NavigationGuardNext) {
  const recipients = await getPaginatedEmailRecipients(Number(to.params.id), Number(to.query.page));
  // vue-router Route type expects all params to be strings but when passing more
  // complex params as props this is no longer the case.
  to.params.recipients = <string>(<unknown>recipients);
  next();
}

export async function campaignCreateGuard(to, _from, next: NavigationGuardNext) {
  const {
    data: { segments, top_locale },
  } = await http().get(`/api/me/brand/campaigns/locale-segments`);
  to.params.defaultSegment = to.query.defaultSegment;
  to.params.defaultLanguage = to.query.defaultLanguage;
  to.params.segments = segments;
  to.params.topLocale = top_locale;
  if (isEnabled(FeatureFlag.NewCreateCampaigns)) {
    Store.dispatch('account/contactManagement/fetchFilters');
    Store.dispatch(
      'account/changeSaveBarMode',
      SaveBarMode.CreateCampaign
    );
    await Store.dispatch('account/contactManagement/fetchContacts', true);
    if (!Store.getters['account/contactManagement/noFiltersApplied']) {
      Store.dispatch('account/contactManagement/fetchContacts');
    }
  }
  next();
}

export async function accountNetworkGuard(_to, _from, next: NavigationGuardNext) {
  Store.dispatch('account/contactManagement/fetchContacts', true);
  Store.dispatch('account/contactManagement/fetchFilters');
  next();
}

export async function accountBillingGuard(_to, _from, next: NavigationGuardNext) {
  const hasFulfillment = !Store.getters['userIsRetailer'] && Store.getters['user']?.business.brand?.fulfillment_enabled;
  const adsWithSepaEnabled = isEnabled('MNY_321') && isEnabled('MNY_115');

  if (hasFulfillment || adsWithSepaEnabled) {
    return next();
  } else {
    return next({ name: 'PageNotFound' });
  }
}

export async function accountSubscriptionGuard(
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const program = Store.getters['offers/ankorstorePlus'];

  if (!program?.optIn) {
    next('/connected');
  } else {
    next();
  }
}

export async function brandCollectionGuard(to, _from, next: NavigationGuardNext) {
  const brandId = getIdFromSlug(to.params.brand);
  const brand = Store.getters['shop/brandById'](brandId);
  if (!brand?.isRefreshed) {
    await Store.dispatch('shop/fetchBrand', {
      brand_id: brandId,
    });
  }
  return next();
}

export async function brandDetailGuard(to, _from, next: NavigationGuardNext) {
  const brandId = parseInt(to.params.brand.split('-').slice(-1)[0], 10);
  const brand = Store.getters['shop/brandById'](brandId);

  if (!brand?.isRefreshed) {
    try {
      await Store.dispatch('shop/fetchBrand', {
        brand_id: brandId,
      });
    } catch (err) {
      // In case error with the parser
      return next({ name: 'PageNotFound' });
    }
  }

  await BrandTracking.track(brandId, null);

  //prevent browsing invalid page number

  if (!to.query?.p) {
    return next();
  }

  const page = Number(to.query?.p);
  if (page === 1 || Number.isNaN(page)) {
    const { p, ...query } = to.query;
    return next(Object.assign({}, to, { query }));
  }
  return next();
}

export async function productDetailGuard(to, _from, next: NavigationGuardNext) {
  const brandId = parseInt(to.params.brand.split('-').slice(-1)[0], 10);
  const productId = parseInt(to.params.product.split('-').slice(-1)[0], 10);

  await BrandTracking.track(brandId, productId);

  try {
    await Store.dispatch('fetchProduct', {
      product_id: productId,
    });
  } catch (err) {
    // In case error with the parser
    return next({ name: 'PageNotFound' });
  }

  next();
}

export async function myListGuard(_to, _from, next: NavigationGuardNext) {
  await Promise.all([Store.dispatch('wishlist/markTooltipAsSeen'), Store.dispatch('wishlist/resetRetailerProductLikeCount')]);
  next();
}

export async function globalBeforeEachGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, router: Router) {
  if (!Store.state?.offers?.events.length) {
    await Store.dispatch('offers/' + FETCH_OFFERS_AND_EVENTS);
  }
  loadCarts(to);
  const lang = document.documentElement.lang;
  if (lang) {
    await loadLanguageAsync(lang, i18n);

    if (!router.app) {
      return false;
    }

    if (
      !isEnabled('activate_router_from_account_to_marketplace') &&
      from.matched[0]?.path === '/account' &&
      to.matched[0]?.path !== '/account'
    ) {
      window.location.href = window.location.origin + to.fullPath;
      return false;
    }
    if (Store.getters['account/getSaveBarMode'] !== SaveBarMode.Disabled) {
      Store.dispatch('account/displayDiscardChanges', to);
      if (Store.getters['account/isPopinDisplayed']) {
        Store.dispatch('account/changeSaveBarMode', SaveBarMode.Disabled);
        return true;
      } else {
        return false;
      }
    } else {
      Store.dispatch('account/products/changeEditionMode', EditionMode.ProductActivation);
      if (to.hash.indexOf('organize') === -1) {
        Store.dispatch('account/changeSaveBarMode', SaveBarMode.Disabled);
      }
      return true;
    }
  } else {
    return false;
  }
}

export function globalAfterEachGuard(to, from) {
  Analytics.page({ to, from });
  if (from?.name) {
    syncLanguageSwitcherUrls(to.path, Store.getters.isUserAuthenticated);
  }
}

export async function landingSubscriptionProgramGuard(_to, _from, next: NavigationGuardNext) {
  const offerProgram = Store.getters['offers/ankorstorePlus'];

  if (Store.getters.userIsRetailer && !offerProgram) {
    next('/connected');
  } else if (offerProgram?.optIn) {
    next({ name: 'ankorstore-plus' });
  } else {
    next();
  }
}

export const isLoggedInGuard = (_from, _to, next) => {
  const isUserAuthenticated = Store.getters['isUserAuthenticated'];

  if (!isUserAuthenticated) {
    return next({ path: '/login' })
  }
  next();
};

export async function accountFulfillmentGuard (_to, _from, next: NavigationGuardNext) {
  if (isEnabled('ff-spa')) {
    window.location.href = '/v2/account/fulfilment/replenishments';
  } else {
    next();
  }
}
