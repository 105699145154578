import {
  SET_BUSINESS_EVENTS,
  SET_OFFERS_ELIGIBILITY,
  SET_OFFERS_ELIGIBILITY_FETCHED,
  SET_R2B_OFFERS,
  SET_R2B_OFFERS_FETCHED,
  SET_SUM_UP_ELIGIBILITY,
  SET_SUM_UP_ELIGIBILITY_FETCHED,
  SET_WELCOME_OFFER_ELIGIBILITY,
  SET_WELCOME_OFFER_ELIGIBILITY_FETCHED,
} from '@monolith/legacy/store/offers/mutation-types';
import { R2BOfferConfiguration } from '@monolith/legacy/types/r2b';
import { OffersState } from '@monolith/legacy/store/offers/index';
import { WelcomeVoucherEligibility } from '@monolith/legacy/services/welcome-voucher';
import { BusinessEventPeriode } from '@monolith/legacy/types/business-event';
import { SumUpEligibility } from '@monolith/legacy/services/offer-eligibility';
import { OfferProgramEligibility } from '@monolith/legacy/modules/promote-engage/services/offer';

const offersMutations = {
  [SET_R2B_OFFERS]: (state: OffersState, value: R2BOfferConfiguration): void => {
    state.r2b.offers_config = { ...value };
  },
  [SET_R2B_OFFERS_FETCHED]: (state: OffersState): void => {
    state.r2b.offers_config_fetched = true;
  },
  [SET_SUM_UP_ELIGIBILITY]: (state: OffersState, value: SumUpEligibility): void => {
    state.sumUp.eligibility = { ...value };
  },
  [SET_SUM_UP_ELIGIBILITY_FETCHED]: (state: OffersState): void => {
    state.sumUp.eligibilityFetched = true;
  },
  [SET_WELCOME_OFFER_ELIGIBILITY]: (state: OffersState, value: WelcomeVoucherEligibility): void => {
    state.welcomeOffer.eligibility = { ...value };
  },
  [SET_WELCOME_OFFER_ELIGIBILITY_FETCHED]: (state: OffersState): void => {
    state.welcomeOffer.eligibilityFetched = true;
  },
  [SET_BUSINESS_EVENTS]: (state: OffersState, value: BusinessEventPeriode[]): void => {
    state.events = [...value];
  },
  [SET_OFFERS_ELIGIBILITY]: (state: OffersState, value: OfferProgramEligibility[]): void => {
    if (value) {
      state.offerEligibility.eligibility = [...value];
    }
  },
  [SET_OFFERS_ELIGIBILITY_FETCHED]: (state: OffersState): void => {
    state.offerEligibility.eligibilityFetched = true;
  },
};

export default offersMutations;
export type OffersMutations = typeof offersMutations;
