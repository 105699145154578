import { createRouter, createWebHistory, RouteLocationNormalized } from 'vue-router';
import Store from '@monolith/legacy/store/index';
import { FeatureFlag, isEnabled } from '@monolith/legacy/services/features';

const Account = () => import('@monolith/legacy/components/account/account.vue');
const AccountOrdersRetailer = () => import('@monolith/legacy/components/account/orders/orders-view/retailer/orders-retailer.vue');
const AccountOrdersBrand = () => import('@monolith/legacy/components/account/orders/orders-view/brand/orders-brand.vue');
const AccountReorder = () => import('@monolith/legacy/modules/reorder/pages/account-reorder.vue');
const AccountReorderItemList = () => import('@monolith/legacy/modules/reorder/pages/reorder-item-list.vue');
const AccountCheckouts = () => import('@monolith/legacy/components/account/account-checkouts.vue');
const AccountCheckout = () => import('@monolith/legacy/components/account/account-checkout.vue');
const AccountIntegrations = () => import('@monolith/legacy/components/account/account-integrations/account-integrations.vue');
const AccountIntegrationsDetail = () =>
  import('@monolith/legacy/components/account/account-integrations/private-apps/account-integrations-detail.vue');
const AccountIntegrationsListing = () =>
  import('@monolith/legacy/components/account/account-integrations/private-apps/account-integrations-listing.vue');
const AccountIntegrationsWebhooksDetail = () =>
  import('@monolith/legacy/components/account/account-integrations/private-apps/account-integrations-webhooks-detail.vue');
const AccountFavoriteBrands = () => import('@monolith/legacy/components/account/account-favorite-brands.vue');
const PublicIntegrationsInstall = () =>
  import('@monolith/legacy/components/account/account-integrations/public-apps/public-integrations-install/public-integrations-install.vue');
// Fulfillment
const AccountFulfillment = () => import('@monolith/legacy/components/account/account-fulfillment/account-fulfillment.vue');
const AccountFulfillmentInventory = () =>
  import('@monolith/legacy/components/account/account-fulfillment/fulfillment-inventory/account-fulfillment-inventory.vue');
const AccountFulfillmentLots = () =>
  import('@monolith/legacy/components/account/account-fulfillment/fulfillment-lots/account-fulfillment-lots.vue');
const AccountFulfillmentReplenishments = () =>
  import('@monolith/legacy/components/account/account-fulfillment/fulfillment-replenishments/account-fulfillment-replenishments.vue');
const AccountFulfillmentReplenishmentView = () =>
  import('@monolith/legacy/components/account/account-fulfillment/fulfillment-replenishments/account-fulfillment-replenishment-view.vue');
const AccountFulfillmentReplenishmentsCreateUpdate = () =>
  import(
    '@monolith/legacy/components/account/account-fulfillment/fulfillment-replenishments/account-replenishments-create-update/account-fulfillment-replenishments-create-update.vue'
  );
// Loyalty
const AccountOffersAndEvents = () => import('@monolith/legacy/components/account/account-offers-events/account-offers-events.vue');
const AccountRepeatOffers = () => import('@monolith/legacy/components/account/account-offers-events/repeat-offer/account-repeat-offers.vue');
const AccountEvent = () => import('@monolith/legacy/components/account/account-offers-events/events/account-event.vue');

const AccountExternalOrderCreate = () => import('@monolith/legacy/components/account/orders/external-order-create/external-order-create.vue');
const AccountBilling = () => import('@monolith/legacy/components/account/account-billings/account-billing.vue');
const AccountBillingList = () => import('@monolith/legacy/components/account/account-billings/billing-list/billing-list.vue');
const AccountBillingSetup = () => import('@monolith/legacy/components/account/account-billings/billing-setup/billing-setup.vue');

const AccountOrderEdit = () => import('@monolith/legacy/components/account/orders/order/order-edit/order-edit.vue');
const AccountOrder = () => import('@monolith/legacy/components/account/orders/order-layout/order-layout.vue');
const AccountPreorder = () => import('@monolith/legacy/components/account/preorders/preorder/preorder.vue');
const AccountPreorders = () => import('@monolith/legacy/components/account/preorders/preorders-page.vue');
const AccountPayments = () => import('@monolith/legacy/components/account/account-payments/account-payments.vue');
const AccountPreparedOrdersList = () =>
  import('@monolith/legacy/components/account/prepared-orders/prepared-orders-list/account-prepared-orders-list.vue');
const AccountProducts = () => import('@monolith/legacy/components/account/account-products.vue');
const AccountProductCollections = () => import('@monolith/legacy/components/account/account-product-collections.vue');
const AccountProductCollectionsEdit = () => import('@monolith/legacy/components/account/product-collections/product-collections-edit.vue');
const AccountProductCollectionsList = () => import('@monolith/legacy/components/account/product-collections/product-collections-list.vue');
const AccountStore = () => import('@monolith/legacy/components/account/account-store.vue');
const AccountPreparedOrder = () => import('@monolith/legacy/components/account/prepared-orders/brand-prepared-order-preview/prepared-order.vue');
const RetailerPreparedOrderPreview = () =>
  import('@monolith/legacy/components/account/prepared-orders/retailer-prepared-order-preview/retailer-prepared-order-preview.vue');
const AccountSettings = () => import('@monolith/legacy/components/account/account-settings/account-settings.vue');
const BrandSettingsPage = () => import('@monolith/legacy/modules/brand-account/pages/settings/settings.page.vue');
const AccountWallet = () => import('@monolith/legacy/components/account/account-wallet.vue');
const SuggestBrand = () => import('@monolith/legacy/components/account/account-suggest-brand.vue');
const PrivateOffers = () => import('@monolith/legacy/components/account/account-private-offers.vue');
const CampaignsPage = () => import('@monolith/legacy/components/account/campaigns/campaigns-page.vue');
const CampaignListPage = () => import('@monolith/legacy/components/account/email-campaigns/campaign-list-page.vue');
const CampaignDetailPage = () => import('@monolith/legacy/components/account/email-campaigns/campaign-detail-page.vue');
const CampaignRecipientsPage = () => import('@monolith/legacy/components/account/email-campaigns/campaign-recipients-page.vue');
const CreateCampaignsPage = async () => {
  if (isEnabled(FeatureFlag.NewCreateCampaigns)) {
    const { CreateCampaigns } = await import('@bc/brands');
    return CreateCampaigns;
  } else {
    return import('@monolith/legacy/components/account/campaigns/create-campaigns-page.vue');
  }
};
const CampaignPreferencesPage = () => import('@monolith/legacy/components/campaign-preferences/campaign-preferences-page.vue');
const AccountMessages = () => import('@monolith/legacy/components/account/message/message-list.vue');
const AccountMessagesDetail = () => import('@monolith/legacy/components/account/message/message-detail.vue');
const AccountAddProduct = () => import('@monolith/legacy/components/account/account-add-product.vue');
const AccountEditProduct = () => import('@monolith/legacy/components/account/account-edit-product.vue');
const AccountNetwork = () => import('@monolith/legacy/components/account/network/network.vue');
const AccountBrandAcceleratorProgram = () => import('@monolith/legacy/components/account/account-brand-accelerator-program.vue');
const PublicIntegrationDetail = () =>
  import('@monolith/legacy/components/account/account-integrations/public-apps/public-integration-detail.vue');
const AccountAnalytics = () => import('@monolith/legacy/components/account/account-analytics/account-analytics.vue');
const Brand = () => import('@monolith/legacy/components/brand/brand.vue');
const Product = () => import('@monolith/legacy/components/brand/product.vue');
const ProductCollection = () => import('@monolith/legacy/components/brand/product-collection.vue');
const MyList = () => import('@monolith/legacy/components/pages/my-list.vue');
const LiftPage = () => import('@monolith/legacy/components/lift/lift-page.vue');
const ReviewForm = () => import('@monolith/legacy/components/review/review-form.vue');
const BrandOnboardingLogin = () =>
  import('@monolith/legacy/components/brand-registration/brand-onboarding/authentication/brand-onboarding-login.vue');
const BrandOnboardingSignup = () =>
  import('@monolith/legacy/components/brand-registration/brand-onboarding/authentication/brand-onboarding-signup.vue');
const BrandRegistrationView = () => import('@monolith/legacy/components/brand-registration/brand-registration-view.vue');
const ReviewThankYouPage = () => import('@monolith/legacy/components/review/review-thank-you-page.vue');
const OrderPage = () => import('@monolith/legacy/components/account/orders/order-page.vue');
const CartPage = () => import('@monolith/legacy/modules/cart-2/pages/cart.vue');
const AccountOrderCreate = () => import('@monolith/legacy/components/account/prepared-orders/create-prepared-order/order-create.vue');
const ProductDraftPreview = () => import('@monolith/legacy/components/preview/product-draft-preview.vue');
const CheckoutPage = () => import('@monolith/legacy/modules/cart-2/pages/checkout-page.vue');
const CheckoutChild = () =>
  import(
    isEnabled(FeatureFlag.CartnCheckoutRelease3_1) ? '@monolith/legacy/modules/cart-2/pages/checkout-child.vue' : '@monolith/legacy/modules/cart-2/checkout.vue'
  );
const PaymentChild = () => import('@monolith/legacy/modules/cart-2/pages/payment-child.vue');
const PreorderPayment = () => import('@monolith/legacy/modules/cart-2/pages/preorder-payment.vue');
const nafoCustomerCreate = () =>
  import('@monolith/legacy/components/account/orders/external-order-create/nafo-customer-create/nafo-customer-create.vue');
import { mapGetters } from 'vuex';
import {
  ConnectedHomeRoutes,
  BoutiqueRoutes,
  CollectionRoutes,
  createCategoryRoutes,
  searchRoutes,
  wholesaleRoutes,
  PreordersCatalog,
} from '@bc/discovery';

const PublicIntegrations = () => import('@monolith/legacy/components/account/account-integrations/public-apps/public-integrations.vue');
const ReviewsBrand = () => import('@monolith/legacy/components/account/reviews/reviews-brand.vue');

const PageNotFound = () => import('@monolith/legacy/components/core/page-not-found/page-not-found.vue');
import { getBrandAccountSettingsRoutes } from '@monolith/legacy/modules/brand-account/brand-account.router';
import {
  accountBillingGuard,
  accountIntegrationsGuard,
  accountNetworkGuard,
  accountSubscriptionGuard,
  brandCollectionGuard,
  brandDetailGuard,
  campaignCreateGuard,
  campaignDetailGuard,
  campaignGuard,
  campaignRecipientsGuard,
  editPreparedOrderGuard,
  globalAfterEachGuard,
  globalBeforeEachGuard,
  landingSubscriptionProgramGuard,
  myListGuard,
  orderListGuard,
  preparedOrderGuard,
  productDetailGuard,
  isLoggedInGuard,
  accountFulfillmentGuard,
} from '@core/routes/router-guard';
import { brandPropsParser, productCollectionPropsParser, productPropsParser } from '@core/routes/router-util';
import { AdsDashboardRoutes } from '@bc/advertisement';
import i18n, { loadLanguageAsync } from '@monolith/legacy/services/i18n';
import { capturePurchasingException } from '@core/plugins/sentry/helper';
import { getPreorderRetailer } from '@monolith/legacy/services/api/cart-preorder';
import { scrollBehavior } from '@core/routes/scroll-behaviour';

const SubscriptionProgramV2 = () => import('@monolith/legacy/modules/promote-engage/subscription-program-v2/subscription-program.vue');
const AccountAnkorstorePlus = () => import('@monolith/legacy/components/account/account-ankorstore-plus/account-ankorstore-plus.vue');

const vueOptions = {};

let router;

export function isBrandPath(path: string): boolean {
  return path && path.startsWith('/brand/');
}

function isBrandPathValid(path: string): boolean {
  return [/^\/brand\/[a-z\d-]+-[\d*]+(\/.*)?$/].some((route) => route.test(path));
}

export function isPathValid(path: string): boolean {
  switch (true) {
    case isBrandPath(path): {
      return isBrandPathValid(path);
    }
    case path === '/landing': {
      return false;
    }
    case path === '/': {
      return false;
    }
    default: {
      return true;
    }
  }
}

export function buildRoutes() {
  return [
    {
      path: '/account',
      component: Account,
      beforeEnter: isLoggedInGuard,
      children: [
        ...AdsDashboardRoutes,
        {
          path: 'checkouts',
          name: 'checkout-list',
          component: AccountCheckouts,
        },
        {
          path: 'checkouts/:id',
          name: 'checkout-detail',
          component: AccountCheckout,
        },
        {
          path: 'reorder',
          name: 'account-reorder',
          component: AccountReorder,
        },
        {
          path: 'reorder/:brandId',
          name: 'reorder-brand-detail',
          component: AccountReorderItemList,
        },
        {
          path: 'orders',
          name: 'order-list',
          component: Store.getters.userIsRetailer ? AccountOrdersRetailer : AccountOrdersBrand,
          beforeEnter: orderListGuard,
        },
        {
          path: 'preorders',
          name: 'preorder-list',
          component: AccountPreorders,
        },
        {
          path: 'orders/create',
          name: 'create-nafo-form',
          component: AccountExternalOrderCreate,
        },
        {
          path: 'orders/create/customer/:id?',
          name: 'create-nafo-customer-form',
          component: nafoCustomerCreate,
        },
        {
          path: 'orders/:id',
          component: OrderPage,
          children: [
            {
              path: '',
              name: 'order-detail',
              component: AccountOrder,
            },
            {
              path: 'track',
              name: 'order-tracking',
              component: AccountOrder,
            },
            {
              path: 'edit',
              name: 'order-edit',
              component: AccountOrderEdit,
            },
            {
              path: 'review/write',
              name: 'order-review-write',
              component: ReviewForm,
              props: (route: RouteLocationNormalized) => ({ overallRating: Number(route.query.overall) }),
            },
            {
              path: 'review/thank-you-page',
              name: 'order-review-thank-you-page',
              component: ReviewThankYouPage,
            },
          ],
        },
        {
          path: 'preorders/:id',
          name: 'preorder-detail',
          component: AccountPreorder,
        },
        {
          path: 'prepared-orders',
          name: 'account.prepared-orders',
          component: AccountPreparedOrdersList,
          beforeEnter: preparedOrderGuard,
        },
        {
          path: 'prepared-orders/create',
          name: 'account.prepared-orders.create',
          component: AccountOrderCreate,
          beforeEnter: preparedOrderGuard,
        },
        {
          path: 'prepared-orders/:id',
          name: 'account.prepared-orders.preview',
          component: Store.getters.userIsRetailer ? RetailerPreparedOrderPreview : AccountPreparedOrder,
        },
        {
          path: 'prepared-orders/:id/confirm',
          name: 'account.prepared-orders.confirm',
          component: RetailerPreparedOrderPreview,
        },
        {
          path: 'prepared-orders/:id/edit',
          name: 'account.prepared-orders.edit',
          component: AccountOrderCreate,
          beforeEnter: editPreparedOrderGuard,
        },
        {
          path: 'settings',
          name: 'settings',
          component: Store.getters.userIsRetailer ? AccountSettings : BrandSettingsPage,
          children: getBrandAccountSettingsRoutes(),
        },
        {
          path: 'payments',
          name: 'payments',
          component: AccountPayments,
        },
        {
          path: 'analytics-dashboard',
          name: 'analytics-dashboard',
          component: AccountAnalytics,
        },
        {
          path: 'integrations',
          component: AccountIntegrations,
          children: [
            {
              path: '',
              name: 'integrations-listing',
              components: {
                privateTabRouter: AccountIntegrationsListing,
                publicTabRouter: PublicIntegrations,
              },
              beforeEnter: accountIntegrationsGuard,
            },
            {
              path: 'private/create',
              name: 'integrations-create',
              components: {
                privateTabRouter: AccountIntegrationsDetail,
              },
            },
            {
              path: 'private/edit/:id',
              name: 'integrations-edit',
              components: {
                privateTabRouter: AccountIntegrationsDetail,
              },
            },
            {
              path: 'private/edit/:id/subscriptions/create',
              name: 'integrations-subscriptions-create',
              components: {
                privateTabRouter: AccountIntegrationsWebhooksDetail,
              },
            },
            {
              path: 'private/edit/:id/subscriptions/create/:subscriptionId',
              name: 'integrations-subscriptions-edit',
              components: {
                privateTabRouter: AccountIntegrationsWebhooksDetail,
              },
            },
            {
              path: 'public/:name',
              name: 'public-integration-detail',
              components: {
                publicTabRouter: PublicIntegrationDetail,
              },
            },
            {
              path: 'public/:name/install',
              name: 'public-integration-install',
              components: {
                publicTabRouter: PublicIntegrationsInstall,
              },
            },
          ],
        },
        {
          path: 'fulfilment',
          component: AccountFulfillment,
          children: [
            {
              path: '',
              name: 'fulfillment',
              redirect: { name: 'fulfillment-inventory' },
              component: AccountFulfillmentInventory,
            },
            {
              path: 'items',
              name: 'fulfillment-inventory',
              component: AccountFulfillmentInventory,
            },
            {
              path: 'lots',
              name: 'fulfillment-lots',
              component: AccountFulfillmentLots,
            },
            {
              path: 'replenishments',
              name: 'fulfillment-replenishments',
              component: AccountFulfillmentReplenishments,
              beforeEnter: accountFulfillmentGuard,
            },
            {
              path: 'replenishments/create',
              name: 'fulfillment-replenishments-create',
              component: AccountFulfillmentReplenishmentsCreateUpdate,
            },
            {
              path: 'replenishments/:id',
              name: 'fulfillment-replenishment-view',
              component: AccountFulfillmentReplenishmentView,
            },
            {
              path: 'replenishments/:id/update',
              name: 'fulfillment-replenishment-update',
              component: AccountFulfillmentReplenishmentsCreateUpdate,
            },
            {
              path: 'orders/create',
              name: 'external-order-create',
              component: AccountExternalOrderCreate,
            },
          ],
        },
        {
          path: 'billing',
          component: AccountBilling,
          beforeEnter: accountBillingGuard,
          children: [
            {
              path: '',
              name: 'billing-list',
              component: AccountBillingList,
            },
            {
              path: 'setup',
              name: 'billing-setup',
              component: AccountBillingSetup,
            },
          ],
        },
        {
          path: 'products',
          name: 'products-list',
          component: AccountProducts,
          props: true,
        },
        {
          path: 'product-collections',
          component: AccountProductCollections,
          children: [
            {
              path: '',
              name: 'product-collections-list',
              component: AccountProductCollectionsList,
              props: true,
            },
            {
              path: 'create',
              name: 'product-collections-create',
              component: AccountProductCollectionsEdit,
            },
            {
              path: ':productCollectionId',
              name: 'product-collections-edit',
              props: true,
              component: AccountProductCollectionsEdit,
            },
          ],
        },
        {
          path: 'store',
          name: 'store-page',
          component: AccountStore,
        },
        {
          path: 'reviews-brand',
          name: 'reviews-brand',
          component: ReviewsBrand,
        },
        {
          path: 'products/add',
          name: 'product-add',
          component: AccountAddProduct,
        },
        {
          path: 'products/:id',
          name: 'product-edit',
          component: AccountEditProduct,
        },
        {
          path: 'product-drafts',
          name: 'product-drafts-list',
          component: AccountProducts,
        },
        {
          path: 'product-drafts/:id',
          name: 'product-draft-edit',
          component: AccountEditProduct,
        },
        {
          path: 'favoriteBrands',
          name: 'favoriteBrands',
          component: AccountFavoriteBrands,
        },
        {
          path: 'wallet',
          name: 'wallet',
          component: AccountWallet,
        },
        {
          path: 'ankorstore-plus',
          name: 'ankorstore-plus',
          component: AccountAnkorstorePlus,
          beforeEnter: accountSubscriptionGuard,
        },
        {
          path: 'suggestBrand',
          name: 'suggestBrand',
          component: SuggestBrand,
        },
        {
          path: 'private-offers',
          name: 'private-offers',
          component: PrivateOffers,
        },
        {
          path: 'campaigns',
          name: 'campaigns',
          props: true,
          component: isEnabled('comms.tgcs_46') ? CampaignListPage : CampaignsPage,
          beforeEnter: campaignGuard,
        },
        {
          path: 'campaigns/campaign/:id',
          name: 'campaigns-detail',
          component: CampaignDetailPage,
          props: true,
          beforeEnter: campaignDetailGuard,
        },
        {
          path: 'campaigns/campaign/:id/recipients',
          name: 'campaigns-recipients',
          component: CampaignRecipientsPage,
          props: true,
          beforeEnter: campaignRecipientsGuard,
        },
        {
          path: 'campaigns/add',
          name: 'campaign-add',
          component: CreateCampaignsPage,
          props: true,
          beforeEnter: campaignCreateGuard,
        },
        {
          path: 'messages',
          name: 'messages-list',
          component: AccountMessages,
        },
        {
          path: 'messages/new',
          name: 'messages-new',
          component: AccountMessagesDetail,
        },
        {
          path: 'messages/:id',
          name: 'messages-detail',
          component: AccountMessagesDetail,
        },
        {
          path: 'network',
          name: 'network',
          component: AccountNetwork,
          beforeEnter: accountNetworkGuard,
        },
        {
          path: 'accelerator-programme',
          name: 'accelerator-programme',
          component: AccountBrandAcceleratorProgram,
        },
        {
          path: 'download-accountancy-zip-files',
          name: 'download-accountancy-zip-files',
          children: [
            {
              path: 'show',
              name: 'download-accountancy-zip-files-show',
            },
            {
              path: ':file',
              name: 'download-accountancy-zip-files-download',
            },
          ],
        },
        {
          path: 'offers-and-events',
          name: 'offers-and-events',
          component: AccountOffersAndEvents,
        },
        {
          path: 'offers-and-events/repeat-offers',
          name: 'repeat-offers',
          component: AccountRepeatOffers,
        },
        {
          path: 'offers-and-events/event/:id',
          name: 'event',
          props: true,
          component: AccountEvent,
        },
      ],
    },
    {
      path: '/ankorstore-plus-signup',
      name: 'ankorstore-plus-signup',
      component: SubscriptionProgramV2,
      beforeEnter: landingSubscriptionProgramGuard,
    },
    {
      name: 'lift_page',
      path: '/lift/:brand',
      component: LiftPage,
    },
    ...createCategoryRoutes(),
    ...searchRoutes,
    {
      name: 'brand-collection',
      path: '/brand/:brand/collections/:collectionSlug',
      component: ProductCollection,
      props: productCollectionPropsParser,
      beforeEnter: brandCollectionGuard,
    },
    {
      name: 'brand',
      path: '/brand/:brand',
      component: Brand,
      props: brandPropsParser,
      beforeEnter: brandDetailGuard,
      meta: {
        scrollContainer: '#catalog-hits-tiles-container',
      },
    },
    {
      name: 'product',
      path: '/brand/:brand/:product',
      component: Product,
      props: productPropsParser,
      beforeEnter: productDetailGuard,
    },
    {
      name: 'mylist',
      path: '/my-list',
      component: MyList,
      beforeEnter: [isLoggedInGuard, myListGuard],
    },
    {
      name: 'product-draft-preview',
      path: '/preview/product-drafts/:id',
      component: ProductDraftPreview,
    },
    {
      name: 'brand-onboarding',
      path: '/brand-onboarding',
      component: BrandRegistrationView,
      children: [
        {
          name: 'login',
          path: 'login',
          component: BrandOnboardingLogin,
        },
        {
          name: 'signup',
          path: 'signup',
          component: BrandOnboardingSignup,
        },
      ],
    },
    ...ConnectedHomeRoutes,
    {
      name: 'carts',
      path: '/carts',
      component: CartPage,
      beforeEnter: isLoggedInGuard,
    },
    {
      path: '/checkout',
      component: CheckoutPage,
      async beforeEnter(to, from: RouteLocationNormalized, next) {
        to.params.retailerId = await Store.getters.retailer?.uuid;
        return isLoggedInGuard(to, from, next);
      },
      children: [
        {
          name: 'checkout',
          path: '',
          component: CheckoutChild,
          async beforeEnter(to, _from, next) {
            to.params.cartId = await Store.getters.retailer?.cart_uuid;
            next();
          },
        },
        {
          name: 'payment',
          path: 'payment/:cartId',
          component: PaymentChild,
          props: true,
          async beforeEnter(to, _from, next) {
            to.params.cartId = await Store.getters.retailer?.cart_uuid;
            to.params.businessAddress = await Store.getters.retailer?.business.addresses[0];
            next();
          },
        },
        {
          name: 'preorderPayment',
          path: 'preorder-payment/:checkoutId',
          component: PreorderPayment,
          props: true,
          async beforeEnter(to, _from, next) {
            to.params.cartUuid = Store.getters.retailer.cart_uuid;
            try {
              const result = await getPreorderRetailer();
              to.params.ibanLast4 = result.ibanLast4;
              next();
            } catch (error) {
              capturePurchasingException(
                error,
                [
                  {
                    label: 'ibanLast4',
                    value: to.params.ibanLast4 as string,
                  },
                ],
                'fatal'
              );
              next(error);
            }
          },
        },
      ],
    },
    ...wholesaleRoutes,
    {
      name: 'campaign-preferences',
      path: '/campaign-preferences',
      component: CampaignPreferencesPage,
      props: true,
    },
    ...PreordersCatalog,
    ...BoutiqueRoutes,
    ...CollectionRoutes,
    {
      path: '/:pathMatch(.*)*',
      name: 'PageNotFound',
      component: PageNotFound,
    },
  ];
}

if (isPathValid(globalThis.location.pathname)) {
  const routes = buildRoutes();

  router = createRouter({
    routes,
    scrollBehavior,
    history: createWebHistory('/'),
  });

  router.beforeEach((to, from) => globalBeforeEachGuard(to, from, router));

  router.afterEach(globalAfterEachGuard);

  vueOptions.router = router;
  vueOptions.computed = {
    ...mapGetters(['user']),
  };
} else {
  loadLanguage();
}

async function loadLanguage() {
  const lang = document.documentElement.lang;
  if (lang) {
    await loadLanguageAsync(lang, i18n);
  }
}

export { vueOptions, router };
