import { ShipmentMethod } from '@monolith/legacy/types/shipping/shipment-method';
import { Amount } from '@core/types/amount';
import { CollectionMethod } from '@monolith/legacy/types/shipping/shipping-service';

export enum ShippingMethodUnavailableReason {
  ShippingCorridorNotBetweenEu8Countries = 'shipping_corridor_not_between_eu8_countries',
  ShipFromOrShipToInFrenchOverseasTerritory = 'shipfrom_or_shipto_in_french_overseas_territory',
  ShipFromOrShipToInSpanishNonPeninsularTerritory = 'shipfrom_or_shipto_in_spanish_non_peninsular_territory',
  InternationalShippingProductsWithoutHSCode = 'international_shipping_products_without_hs_code',
  InternationalShippingProductsWithoutMadeIn = 'international_shipping_products_without_made_in',
  AnkorstoreLabelsMethodDisabledForThisOrder = 'ankorstore_labels_method_disabled_for_this_order',
  UPSShippingCountryDoesNotMatchBillingCountry = 'ups_shipping_country_does_not_match_billing_country',
}

export interface ShippingMethod {
  name: ShipmentMethod;
  canBeUsed: boolean;
  errors:
    | {
        generic?: string[];
        service?: string[];
      }
    | { code: ShippingMethodUnavailableReason; message: string }[];
}

export interface ShippingEstimation {
  rateProvider: string;
  rateAmount: Amount;
  collectionMethods: CollectionMethod[];
  estimatedParcels: ShippingParcel[];
}

export interface ShippingParcel {
  weight: number;
  height: number;
  length: number;
  width: number;
  distanceUnit: 'cm';
}
