<template>
  <AkAlert
    v-if="discount && discount.status"
    class="discount-status"
    :hide-icon="true"
    type="discount"
  >
    <div class="ds-flex ds-justify-center">
      <img
        src="/images/gift.svg"
        class="discount-status__icon"
        alt="gift"
      >
      <div class="discount-status__content">
        <div class="ak-alert__title">
          {{ $t('Congratulations!') }}
        </div>
        <AkParagraph
          class="mb-0"
          size="sm"
        >
          {{
            $t("You've unlocked {discount}% off every order you place until {date}.", {
              discount: discount.discount,
              date: formattedDate,
            })
          }}
        </AkParagraph>
        <AkButton
          class="ds-font-normal"
          link
          @click="onCTAClick"
        >
          {{ $t('shop now') }}
        </AkButton>
      </div>
    </div>
  </AkAlert>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { formatShortDate } from '@monolith/legacy/services/date-locale';
import { AkAlert, AkButton, AkParagraph } from '@ankorstore/design-system';
import Analytics from '@monolith/legacy/services/analytics';
import { DiscountStatusCTAClickedEvent } from '@monolith/legacy/services/analytics/events/discount-status-cta-clicked.event';
import { offerEligibility } from '@monolith/legacy/services/offer-eligibility';
import { mapActions, mapGetters } from 'vuex';
import { FETCH_OFFERS_AND_EVENTS } from '@monolith/legacy/store/offers/action-types';
import { FeatureFlag } from '@monolith/legacy/services/features';

const OFFER_ID = 'sumup';

export default defineComponent({
  name: 'DiscountStatus',
  components: { AkAlert, AkButton, AkParagraph },
  data() {
    return {
      discount: null,
    };
  },
  computed: {
    ...mapGetters('offers', ['welcomeOffer']),
    formattedDate(): string {
      return formatShortDate(this?.discount?.endDate);
    },
  },
  async created() {
    await this.fetchOffersAndEvents();

    this.discount = this.$isEnabled(FeatureFlag.WelcomeDiscountOffer) && this.welcomeOffer;
    if (!this.discount?.status) {
      this.discount = await offerEligibility(OFFER_ID);
    }
  },
  methods: {
    ...mapActions('offers', [FETCH_OFFERS_AND_EVENTS]),
    async onCTAClick() {
      await Analytics.track(new DiscountStatusCTAClickedEvent());
      window.location.href = '/shop/new';
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.discount-status {
  @apply ds-mb-4;

  &__icon {
    @apply ds-w-9 ds-mr-3 ds-hidden sm:ds-block;
    min-width: 85px;
  }

  &__content {
    @apply ds-flex ds-flex-col ds-items-center ds-text-center sm:ds-items-start sm:ds-text-left;
  }
}
</style>
