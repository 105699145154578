<template>
  <div class="campaigns-language">
    <AkHeading4 class="ds-mb-4">
      {{ t('brand.account.campaigns.language.heading') }}
    </AkHeading4>
    <AkTable
      ref="campaignsLanguage"
      :columns="columns"
      :data="tableItems"
      data-testid="campaigns-table"
    >
      <template #[`item.retailersLanguage`]="{ item }">
        <AkRadio
          :value="item.retailersLanguageValue"
          :is-checked="selectedLanguage === item.retailersLanguageValue"
          name="campaigns-retailers-language"
          :text="item.retailersLanguage"
          class="radio-language"
          data-testid="retailers-language"
          @click.prevent="onClick($event, item.retailersLanguageValue)"
        />
      </template>

      <template #[`item.retailersCount`]="{ item }">
        <p data-testid="number-of-contacts">
          {{ item.retailersCount }}
        </p>
        <p
          v-if="item.excludedCount"
          data-testid="number-of-excluded-contacts"
          class="ds-text-neutral-700 ds-flex"
        >
          <AkIcon
            symbol="volume-mute"
            class="ds-mr-1"
          /> {{ item.excludedCount }} {{ t('brand.account.campaigns.language.inactive', item.excludedCount) }}
        </p>
      </template>

      <template #[`item.templateLanguage`]="{ item }">
        <p data-testid="template-language">
          {{ item.templateLanguage }}
        </p>
      </template>
    </AkTable>
    <div
      v-if="hasZeroContacts || noFiltersApplied"
      class="ds-leading-5 ds-text-center ds-text-neutral-700 ds-pt-6"
    >
      {{ t('brand.account.campaigns.language.noContactsSelected.line1') }}
      <br>
      {{ t('brand.account.campaigns.language.noContactsSelected.line2') }}
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, watch, computed } from 'vue';
import { AkHeading4, AkTable, AkRadio, AkTableColumn } from '@ankorstore/design-system';
import { useI18n } from 'vue-i18n';
import { AkLocale } from '@core/types/base';
import type { LanguagesInterface } from '../../types/create-campaigns.types';
import { useStore } from '@core/composables/use-store';
import { getGlobalConfig } from '@monolith/legacy/services/global-config';
import { type ValueContactFacet } from '@bc/brands/domain';

export interface TableItemsInterface {
  retailersLanguageValue: AkLocale;
  retailersLanguage: string;
  templateLanguage: string;
}

export default defineComponent({
  name: 'CampaignsLanguage',
  components: {
    AkHeading4,
    AkTable,
    AkRadio,
  },
  props: {
    selectedLanguage: {
      type: String,
      required: true,
    },
    unsubscribedByLanguage: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['selectTemplateLanguage', 'selectTemplateLanguageClick'],
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();
    const selectedRetailersLanguage = ref<string | null>(null);

    const languageFacets = computed(
      (): ValueContactFacet['data'] => store.getters['account/contactManagement/getLanguageFacets']?.data
    );
    const languageFacetsDynamic = computed(
      (): ValueContactFacet['data'] => store.getters['account/contactManagement/getLanguageFacetsDynamic']?.data
    );

    const noFiltersApplied = computed((): boolean => store.getters['account/contactManagement/noFiltersApplied']);
    const hasZeroContacts = computed((): boolean => store.getters['account/contactManagement/hasZeroContacts']);

    const getLanguages = computed((): LanguagesInterface[] => {
      if (!languageFacets.value || noFiltersApplied.value) {
        return [];
      }
      const languages = [] as LanguagesInterface[];
      // if all languages have zero we display all - temporary solution till we implement notification
      for (const lang of languageFacets.value) {
        const langCount = languageFacetsDynamic.value?.find((item) => item.value === lang.value)?.count || 0;
        if (langCount === 0){
          continue;
        }
        const label = getGlobalConfig().opened_locales[lang.value];
        languages.push(
          !!label && {
            label: `${label}`,
            value: lang.value as AkLocale,
            isDefault: props.selectedLanguage === lang.value,
            count: langCount,
            excludedCount: props.unsubscribedByLanguage?.[lang.value] ?? null,
          }
        );
      }

      return languages;
    });

    const tableItems = computed((): TableItemsInterface[] => {
      const items = getLanguages.value.map((language) => {
        if (language) {
          return {
            retailersLanguageValue: language.value,
            retailersLanguage: language.label,
            retailersCount: language.count,
            excludedCount: language.excludedCount,
            templateLanguage: language.label,
          };
        }
        return {} as TableItemsInterface;
      });

      const filtered = items.filter((item) => Object.keys(item).length > 0);
      return filtered;
    });

    const columns = computed((): AkTableColumn[] => [
      {
        name: 'retailersLanguage',
        align: 'left',
        label: t('brand.account.campaigns.language.label.retailersLanguage'),
        tooltip: {
          title: t('brand.account.campaigns.language.tooltip.retailersLanguage'),
        },
      },
      {
        name: 'retailersCount',
        align: 'left',
        label: t('brand.account.campaigns.language.label.numberOfContacts'),
      },
      {
        name: 'templateLanguage',
        align: 'left',
        label: t('brand.account.campaigns.language.label.templateLanguage'),
      },
    ]);

    const onClick = (_checked: boolean, value: string): void => {
      selectedRetailersLanguage.value = value;
      emit('selectTemplateLanguageClick', selectedRetailersLanguage.value);
    };

    watch(
      getLanguages,
      (newLanguages) => {
        if (newLanguages) {
          const preSelectedLanguage = newLanguages.find((item) => {
            return item.isDefault && item.count !== 0;
          }) || newLanguages.find((item) => {
            return item.count !== 0;
          });

          selectedRetailersLanguage.value = preSelectedLanguage?.value;

          // if we fallback to default language, we must tell the parent component
          emit('selectTemplateLanguage', selectedRetailersLanguage.value);
        }
      },
      { deep: true }
    );

    return {
      columns,
      t,
      tableItems,
      onClick,
      selectedRetailersLanguage,
      getLanguages,
      languageFacetsDynamic,
      hasZeroContacts,
      noFiltersApplied
    };
  },
});
</script>

<style lang="scss" scoped>
.radio-language {
  @apply ds-text-sm #{!important};
}
</style>
