export interface Image {
  url: string;
  alt: string;
  dimensions: { width: number; height: number };
}

export type ElementType =
  | 'heading1'
  | 'heading2'
  | 'heading3'
  | 'heading4'
  | 'heading5'
  | 'heading6'
  | 'paragraph'
  | 'preformatted'
  | 'strong'
  | 'em'
  | 'list-item'
  | 'o-list-item'
  | 'group-list-item'
  | 'group-o-list-item'
  | 'image'
  | 'embed'
  | 'hyperlink'
  | 'label'
  | 'span';

export interface RichTextItem {
  type: ElementType;
  text: string;
  spans: Array<unknown>;
}

export enum SitewideOfferSliceTypes {
  OfferBanner = 'offer-banner',
  OfferBadge = 'offer-badge',
  OfferMessage = 'offer-message',
}

export type SitewideOfferSliceLabel =
  | 'product-page-offer-banner'
  | 'cart-page-offer-banner'
  | 'checkout-page-offer-banner'
  | 'checkout-confirmation-page-offer-banner'
  | 'brand-catalogue-page-offer-banner'
  | 'brand-page-offer-banner'
  | 'brand-tile-offer-badge'
  | 'product-tile-offer-badge'
  | 'brand-location-filter-offer-message';

export interface PrismicSitewideOfferPayloadSlice {
  slice_label: SitewideOfferSliceLabel;
  slice_type: string;
  primary: {
    heading?: RichTextItem[];
    body?: RichTextItem[];
    bypass_condition?: boolean;
    icon_key?: RichTextItem[];
    content?: RichTextItem[];
    message?: RichTextItem[];
  };
  items: unknown[];
}

export interface PrismicSlice<TPrimary, TItem> {
  slice_type: string;
  primary: TPrimary;
  items: TItem[];
}

export interface PrismicImage {
  dimensions?: { width: number; height: number };
  alt?: string | null;
  copyright?: string | null;
  url?: string;
}

export interface PrismicSitewideOfferPayload {
  'sitewide-offer': {
    [0]: {
      body: PrismicSitewideOfferPayloadSlice[];
    };
  };
}

export interface PrismicLink {
  link_type: string;
  url: string;
}
