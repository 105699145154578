import { OfferEligibilityStatus } from '@monolith/legacy/types/offer';
import http from '@monolith/legacy/services/api/http';
import { captureException } from '@core/plugins/sentry';

export type WelcomeVoucherEligibility = OfferEligibilityStatus;

export const welcomeOfferEligibility = async (isRetailer: boolean): Promise<WelcomeVoucherEligibility> => {
  if (!isRetailer) {
    return {
      status: false,
    };
  }

  try {
    const result = await http().get('/api/me/offers/welcome-discount-eligibility');

    if (result?.data?.data) {
      return {
        status: result.data.data.status,
        discount: result.data.data.discount,
        endDate: result.data.data.end_date ? new Date(result.data.data.end_date) : null,
      };
    }

    return {
      status: false,
    };
  } catch (e) {
    if (e.response.status !== 503) {
      captureException(e);
    }

    return {
      status: false,
    };
  }
};
