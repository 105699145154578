<template>
  <div class="cart-preview">
    <div
      class="cart-preview__header"
      @click="goToCart"
    >
      <div class="cart-preview__header-counts">
        <AkIcon
          class="cart-preview__header-counts-icon"
          symbol="bag"
          size="sm"
        />
        <span class="cart-preview__header-counts-items">
          {{ formatCount(brandsCount) }} {{ $t('Brands') }}
        </span>
        <span class="cart-preview__header-counts-separator">|</span>
        <span class="cart-preview__header-counts-items">
          {{ productsCount }}
          <span class="cart-preview__header-counts-products">
            {{ $t('Products') }}
          </span>
        </span>
      </div>
      <div class="cart-preview__header-counts-amount">
        {{ formatCartTotalAmount }}
      </div>
    </div>

    <div
      v-if="brandsCount && !isLoading"
      class="cart-preview__list"
      :class="brandsCount < 3 ? 'small-preview' : 'big-preview'"
    >
      <div
        v-for="item in itemAggregates"
        :key="item.id"
        class="cart-preview__list-item"
        @click="redirectToPageAndScroll(item.id)"
      >
        <div class="cart-preview__list-item-main">
          <img
            class="cart-preview__list-item-logo"
            :alt="item.brand.data.name"
            :src="imgCdnUrlFill(brandLogoPath(item.brand.data.logoImagePath), '60')"
            @click.stop="goToBrand(item.brand.data.link, item.brand.data.id)"
          >
          <div
            class="cart-preview__list-item-title"
            @click.stop="goToBrand(item.brand.data.link, item.brand.data.id)"
          >
            {{ item.brand.data.name }}
          </div>
        </div>

        <div
          class="cart-preview__list-item-counts"
        >
          <div>
            {{ formatCount(item.itemCount) }}
            <span class="cart-preview__list-item-counts-products">
              {{ $t('Products') }}
            </span>
          </div>
          <div>
            <ItemTotalPrice
              class="cart-preview__list-price"
              :before-discount="item.totalAmountBeforeDiscount"
              :after-discount="item.totalAmountAfterDiscount"
              text-size="sm"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="isLoading"
      class="cart-preview__list-loading"
    >
      <LoaderIcon />
    </div>
    <div
      v-else
      class="cart-preview__list"
    >
      <div class="cart-preview__list-empty-cart">
        <AkIcon
          class="cart-preview__list-empty-cart-icon"
          symbol="cart"
          size="lg"
        />
        <div> {{ $t('cart.empty.title') }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { imgCdnUrlFill } from '@bc/shared';
import { productsCount } from '@monolith/legacy/modules/cart-2/composables/use-counters';
import { useCart } from '@monolith/legacy/modules/cart-2/composables/use-cart';
import { getBrandLogoPath } from '@monolith/legacy/modules/cart-2/utils';
import { computed, onMounted,ref } from 'vue';
import ItemTotalPrice from '@monolith/legacy/modules/cart-2/item-total-price.vue';
import { useRouter } from '@core/composables/use-router';
import {
  trackGoToBrandPageCartPreview,
  trackGoToCartCartPreview,
} from '@monolith/legacy/services/analytics/segment-ecommerce-v2/track';
import LoaderIcon from '@monolith/legacy/components/loader-icon.vue';

const { formatCartTotalAmount, itemAggregates, fetchCart, trackCartPreviewViewedWrapper } = useCart();

const isLoading = ref(false);

onMounted(async() => {
  isLoading.value = true;
  await fetchCart();
  trackCartPreviewViewedWrapper()
  isLoading.value = false;
});

const router = useRouter();

const brandsCount = computed(() => itemAggregates.value?.length);

const formatCount = (value: number): string | number => {
  return value > 0  &&  value < 10 ? '0' + value : value;
};

const brandLogoPath = (logo: string): string => {
  return getBrandLogoPath(logo);
};

const goToCart = () => {
  router.push({ name: 'carts' });
  trackGoToCartCartPreview();
};

const goToBrand = (link: string, id: string) => {
  router.push(link)
  trackGoToBrandPageCartPreview(id)
};

const redirectToPageAndScroll = (elementId: string) => {
  router.push({ name: 'carts', query: { id: elementId } });
  trackGoToCartCartPreview();
};
</script>

<style scoped lang="scss">
.cart-preview {
  @apply  ds-border-solid ds-border ds-border-neutral-200 ds-rounded-b-lg ds-absolute ds-z-10 ds-top-full ds-bg-white ds-w-[375px] ds-right-[-25px];
  &__header{
    @apply ds-flex ds-justify-between ds-font-semibold ds-text-neutral-900 ds-items-center ds-text-sm ds-bg-info-100 ds-cursor-pointer ds-h-[51px] ds-px-4.5;
    &-counts{
      @apply ds-flex ds-items-center;
      &-icon{
        @apply ds-pr-1 ds-font-semibold;
      }
      &-items{
        @apply ds-pt-0.5 ds-lowercase;
      }
      &-separator{
        @apply ds-text-neutral-500 ds-mx-1.5 ds-font-normal;
      }
      &-products {
        @apply ds-font-normal ds-pt-0.5;
      }
    }
    &:hover {
      .cart-preview__header-counts-items {
        @apply ds-underline ds-text-primary;
      }
      .cart-preview__header-counts-products {
        @apply ds-underline ds-text-primary;
      }
      .cart-preview__header-counts-amount {
        @apply ds-text-primary;
      }
      .cart-preview__header-counts-icon {
        @apply ds-text-primary;
      }
    }
  }

  &__list{
    @apply ds-overflow-auto ds-scroll-auto;
    &-loading {
      @apply ds-flex ds-justify-center ds-items-center ds-h-[180px];
    }
    &-empty-cart {
      @apply ds-font-semibold ds-h-[180px] ds-flex ds-justify-center ds-items-center ds-flex-col  ds-text-base ds-text-primary;
      &-icon {
        @apply ds-text-success-700 ds-p-1 ds-mb-1;
      }
    }
    &-item {
      @apply ds-border-b ds-border-neutral-300 ds-border-solid ds-flex ds-text-neutral-900 ds-text-xs ds-font-semibold ds-p-4 ds-cursor-pointer;
      &:hover {
        @apply ds-bg-neutral-100;
        .cart-preview__list-item-logo {
          @apply ds-border-2 ds-border-solid ds-border-accent;
        }
        .cart-preview__list-item-title {
          @apply ds-underline;
        }
      }
      &:last-child {
        @apply ds-rounded-b-lg;
      }
      &-main {
        @apply ds-flex ds-items-center ds-flex-[0_0_58%];
      }
      &-counts {
        @apply ds-flex ds-items-center ds-text-nowrap ds-flex-[0_0_42%] ds-justify-around;
        &-products {
          @apply ds-font-normal ds-mr-3 ds-lowercase;
        }
      }
      &-logo {
        @apply ds-h-6.5 ds-rounded-full ds-border ds-border-neutral-100 ds-border-solid ds-shadow-md;
      }
      &-title {
        @apply ds-text-balance ds-ml-1.5 ds-text-left;
      }
    }
  }
}

:deep(.cart-preview__header-counts .icon-bag) {
  font-size: 1rem;
}

:deep(.item-total-price) {
  @apply ds-flex-col-reverse ds-gap-0;
}

.small-preview {
  @apply ds-h-[180px]
}

.big-preview {
  @apply ds-h-[250px]
}
</style>
