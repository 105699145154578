import http from '@monolith/legacy/services/api/http';
import { deserialize } from '@monolith/legacy/services/utils/jsonapi-parser';
import { Preorder } from '@monolith/legacy/components/account/preorders/preorder/types';

export const getPreorder = async (id: string): Promise<Preorder> => {
  const response = await http().get(
    `/api/internal/v1/ordering/preorders/${id}?include=preorderItems,preorderItems.productVariant,brand,retailer,preorderItems.order`
  );
  return deserialize(response.data).data;
};

export const acceptPreorder = async (id: string): Promise<Preorder> => {
  const response = await http().post(
    `/api/internal/v1/ordering/preorders/${id}/-actions/accept?include=preorderItems,preorderItems.productVariant,brand,retailer,preorderItems.order`
  );
  return deserialize(response.data).data;
};

export const rejectPreorder = async (id: string): Promise<Preorder> => {
  const response = await http().post(
    `/api/internal/v1/ordering/preorders/${id}/-actions/reject?include=preorderItems,preorderItems.productVariant,brand,retailer,preorderItems.order`
  );
  return deserialize(response.data).data;
};

export const listPreorders = async (): Promise<Preorder[]> => {
  const response = await http().get(
    `/api/internal/v1/ordering/preorders?include=preorderItems,preorderItems.productVariant,brand,retailer,preorderItems.order`
  );
  return deserialize(response.data).data;
};
