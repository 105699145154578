import { R2BOfferConfiguration } from '@monolith/legacy/types/r2b';
import mutations from '@monolith/legacy/store/offers/mutations';
import actions from '@monolith/legacy/store/offers/actions';
import getters from '@monolith/legacy/store/offers/getters';
import { WelcomeVoucherEligibility } from '@monolith/legacy/services/welcome-voucher';
import { BusinessEventPeriode } from '@monolith/legacy/types/business-event';
import { SumUpEligibility } from '@monolith/legacy/services/offer-eligibility';
import { OfferProgramEligibility } from '@monolith/legacy/modules/promote-engage/services/offer';

export { OFFERS_STORE_ID } from './constants';

export interface OffersState {
  r2b: {
    offers_config: R2BOfferConfiguration;
    offers_config_fetched: boolean;
  };
  welcomeOffer: {
    eligibility: WelcomeVoucherEligibility;
    eligibilityFetched: boolean;
  };
  sumUp: {
    eligibility: SumUpEligibility;
    eligibilityFetched: boolean;
  };
  events: BusinessEventPeriode[];
  offerEligibility: {
    eligibility: OfferProgramEligibility[];
    eligibilityFetched: boolean;
  };
}

const state: OffersState = {
  r2b: {
    offers_config: null,
    offers_config_fetched: false,
  },
  sumUp: {
    eligibility: {
      status: false,
    },
    eligibilityFetched: false,
  },
  welcomeOffer: {
    eligibility: {
      status: false,
    },
    eligibilityFetched: false,
  },
  events: [],
  offerEligibility: {
    eligibility: [],
    eligibilityFetched: false,
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
